<template>
<div class="head">
  <div class="head-cont contW">
    <img src="../../../assets/images/index/logo.png"  height="28" @click="goHome" />
    <div class="head-cont-r">
      <div class="head-cont-r-item" :class="curTab == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id"><router-link :to="item.url">{{ item.name }}</router-link></div>
<!--      <div class="head-cont-r-item">学习站</div>-->
      <div class="head-cont-r-item" @click="goMyTrain"><a>题库练习</a></div>
      <div class="head-cont-r-item" @click="goPractical"><a >实操练习</a></div>
      <div class="head-btn" @click="goLogin" v-if="!userInfo">登录/注册</div>
      <el-dropdown  v-else trigger="click" split-button type="text"   @command="goMenu" @click.native="goInfo">
        <span class="el-dropdown-link" >
          <img :src="userInfo.avatar" width="30" height="30" />
          {{ userInfo.nickname }}
        </span>
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item v-for="(item,index) in menuList" :key="index" :command="item">{{ item.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
<!--      <el-dropdown  v-else trigger="click" split-button type="primary" @command="goMenu" @click.prevent="goInfo">-->
<!--        <img :src="userInfo.avatar" width="30" height="30" />-->
<!--        {{ userInfo.nickname }}-->

<!--        <el-dropdown-menu slot="dropdown" >-->
<!--          <el-dropdown-item v-for="(item,index) in menuList" :key="index" :command="item">{{ item.name }}</el-dropdown-item>-->
<!--        </el-dropdown-menu>-->
<!--      </el-dropdown>-->
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "mainHead",
  data(){
    return{
      userInfo:'',
      tabList:[
        {
          name:'首页',
          url:'/home',
          id:1
        },
        {
          name:'认证中心',
          url:'/attestation',
          id:2
        },

      ],
      curTab:1,
      menuList:[
        {
          name:'我的学习',
          url:'/user/study'
        },
        {
          name:'我的证书',
          url:'/user/certificate'
        },
        {
          name:'考试记录',
          url:'/user/record'
        },
        // {
        //   name:'我的订单',
        //   url:'/user/order'
        // },
        {
          name:'我的激活码',
          url:'/user/cdKey'
        },
        {
          name:'退出',
          url:'/logout'
        },
      ],
      token:'',
    }
  },
  watch:{
    $route(to,from){

      let path = to.path;

      let tab = this.tabList.filter((item)=>{
        return path.indexOf(item.url) > -1
      });
      if(tab.length){
        this.curTab = tab[0].id;
      }else{
        this.curTab = '';
      }
    },
    '$store.state.payType'(newV){
      if(newV == 1  || newV == 2){
        this.menuList = [
          {
            name:'我的学习',
            url:'/user/study'
          },
          {
            name:'我的证书',
            url:'/user/certificate'
          },
          {
            name:'考试记录',
            url:'/user/record'
          },
          {
            name:'我的订单',
            url:'/user/order'
          },
          {
            name:'我的激活码',
            url:'/user/cdKey'
          },
          {
            name:'退出',
            url:'/logout'
          },
        ]
      }
    }
  },
  created(){
    let userInfo = localStorage.getItem('userInfo');
    this.userInfo = userInfo ? JSON.parse(userInfo).user : '';

    let path = this.$route.path;

    let tab = this.tabList.filter((item)=>{
      return path.indexOf(item.url) > -1
    });
    if(tab.length){
      this.curTab = tab[0].id;
    }else{
      this.curTab = '';
    }

  },
  methods:{
    goLogin(){
      this.$router.push({
        path:'/login'
      })
    },
    goMenu(data){
      console.log(data.url,'data.urldata.url')
      if(data.url == '/logout'){
        this.api.user.logout().then((res)=>{
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          localStorage.removeItem('userInfo');
          this.userInfo = '';
          if(this.$route.path != '/home'){
            this.$router.push({
              path:'/home'
            })
          }
        })
      }else{
        this.$router.push({
          path:data.url
        })
      }

    },
    goInfo(){
      this.$router.push({
        path:'/user/study'
      })
    },
    goHome(){
      this.$router.push({
        path:'/home'
      })
    },
    goPractical(){
      this.$router.push({
        path:'/practical'
      })
    },
    goMyTrain(){
      this.token = localStorage.getItem('token');
      if(this.token){
        this.$router.push({
          path:'/user/study'
        })
      }else{
        this.$router.push({
          path:'/login'
        })
      }

    }
    // changeTab(id){
    //   this.curTab = id;
    // },
    // changeUserList(){
    //   this.curTab = '';
    // }
  }
}
</script>

<style scoped lang="scss">
.head{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 54px;
  background: rgba(255,255,255,1);
  box-shadow: 0 0 20px 0 rgba(0,35,136,0.06);
  z-index: 9;
  .head-cont{
    @include flex(row,space-between,center);
    height: 100%;
    >img{
      cursor: pointer;
    }
  }
  .head-cont-r{
    @include flex(row,flex-end,center);
    .head-cont-r-item{
      margin-right: 64px;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
    }
    .active{
      font-size: 16px;
      font-weight: bold;
      color: $theme-blue;
    }
    .head-btn{
      margin-left: 94px;
      @include btn(100px,36px,18px,14px);
    }
    .head-btn:hover{
      @include btnHover($theme-light-blue);
    }
  }
  .el-dropdown-link{
    @include flex(row,center,center);
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    cursor: pointer;
    >img{
      margin-right: 6px;
    }
  }
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
  color: $theme-blue;
  font-weight: bold;
  background-color: transparent;
}
</style>
<style lang="scss">
.head .el-dropdown .el-button-group{
  display: flex !important;
}
.head .el-button--text{
  color:#333333;
}
.head .el-button--text:focus, .el-button--text:hover{
  color: $theme-blue;
}
</style>
