import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Layout from '../views/layout'
import Home from '../views/home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: Layout,
    children:[
      {
        path: '/home',
        name: 'Home',
        meta:{
          title:'首页'
        },
        component: Home,
      },
      {
        path: '/attestation',
        name: 'attestation',
        meta:{
          title:'认证'
        },
        component: ()=>import('../views/attestation/index.vue'),
      },
      {
        path: '/attestation/detail',
        name: 'attestationDetail',
        meta:{
          title:'认证详情'
        },
        component: ()=>import('../views/attestation/detail.vue'),
      },
      {
        path: '/attestation/order',
        name: 'attestationOrder',
        meta:{
          title:'确认订单'
        },
        component: ()=>import('../views/attestation/order.vue'),
      },
      {
        path: '/attestation/train/:tab?',
        name: 'attestationTrain',
        meta:{
          title:'练习考试'
        },
        component: ()=>import('../views/attestation/train.vue'),
      },
      {
        path: '/attestation/exam',
        name: 'attestationExam',
        meta:{
          title:'开始考试'
        },
        component: ()=>import('../views/attestation/exam.vue'),
      },
      {
        path: '/attestation/examDetail',
        name: 'attestationExamDetail',
        meta:{
          title:'考试详情'
        },
        component: ()=>import('../views/attestation/examDetail.vue'),
      },
      {
        path: '/practical',
        name: 'practical',
        meta:{
          title:'实操练习'
        },
        component: ()=>import('../views/practical/index.vue'),
      },
      {
        path: '/user',
        name: 'user',
        redirect: '/user/study',
        component: ()=>import('../views/user/index.vue'),
        children:[
          {
            path: '/user/study',
            name: 'study',
            meta:{
              title:'我的学习'
            },
            component: ()=>import('../views/user/study.vue'),
          },
          {
            path: '/user/certificate',
            name: 'certificate',
            meta:{
              title:'我的证书'
            },
            component: ()=>import('../views/user/certificate.vue'),
          },
          {
            path: '/user/record',
            name: 'record',
            meta:{
              title:'考试记录'
            },
            component: ()=>import('../views/user/record.vue'),
          },
          {
            path: '/user/order',
            name: 'order',
            meta:{
              title:'我的订单'
            },
            component: ()=>import('../views/user/order.vue'),
          },
          {
            path: '/user/cdKey',
            name: 'cdKey',
            meta:{
              title:'我的激活码'
            },
            component: ()=>import('../views/user/cdKey.vue'),
          }
        ]
      }

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/login/index.vue'),
  },
  {
    path: '/sso-server-auth',
    name: 'sso-server-auth',
    component: ()=>import('../views/login/ssoServerAuth.vue'),
  },
  {
    path: '*',
    redirect:'/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (to) {

  return VueRouterPush.call(this, to).catch(err => err)

}

router.beforeEach((to,form,next)=>{
  if(to.meta.title){
    document.title = to.meta.title + ' - 火山引擎技能认证考试平台';
  }
  store.commit('formatBreadCrumbList',to)
  next();
});

export default router
