<template>
  <el-breadcrumb separator="/" :class="whiteC ? 'whiteC' : ''">
    <el-breadcrumb-item style="color:#FFFFFF" v-for="item in $store.state.breadList" :key="item.url" :to="{ path: item.url,query:item.query,params:item.params }">{{ item.name }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  props:{
    whiteC:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    whiteC(newV) {
      console.log(newV,'newVnewVnewV')
    }
  },
  methods: {

  },
};
</script>
<style lang="scss">
.whiteC .el-breadcrumb__inner a, .whiteC .el-breadcrumb__inner.is-link{
  color: #FEFEFE;
}
.whiteC .el-breadcrumb__separator{
  color: #FEFEFE;
}
.whiteC .el-breadcrumb__item:last-child .el-breadcrumb__inner, .whiteC .el-breadcrumb__item:last-child .el-breadcrumb__inner a,.whiteC .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .whiteC .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
  color: #FEFEFE;
}
</style>
