<template>
  <div class="layout">
    <Head></Head>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from "@/views/layout/components/head";
import Footer from "@/views/layout/components/footer";

export default {
  name: 'layout',
  components: {
    Head,
    Footer
  }
}
</script>
<style scoped lang="scss">
.layout{
  background-color: #FFFFFF;
  height: 100%;
  overflow-y: auto;
  min-width: 1382px;
  overflow-x: hidden;
}
</style>
