import {request} from '@/api/request.js'

//登录-提交
const login = (data) => {
    return request({
        url: '/index/login',
        method: 'post',
        data
    })
}
//获取图片验证码
const getCaptcha = (data) => {
    return request({
        url: '/index/captcha',
        method: 'post',
        data
    })
}
// 火山单点登陆获取code接口
const ssoServerAuth = (data) => {
    return request({
        url: '/oauth/sso-server-auth',
        method: 'post',
        data
    })
}

export default {
    login,
    getCaptcha,
    ssoServerAuth
}
