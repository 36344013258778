<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){
    return{
      payType:['online_pay','cdkcode_pay'],
      token:'',
    }
  },
  created(){
    this.token = localStorage.getItem('token') || '';
    if(this.token){
      this.getList();
    }
  },
  methods:{
    getList(){
      this.api.user.settingList().then((res)=>{
        let payType = [];
        res.list.forEach((item)=>{
          let arr = this.payType.filter((sItem)=>{
            return sItem == item.key && item.value !=0;
          })
          if(arr.length){
            payType.push(item.key);
          }
        });
        //支付方式 0 激活码支付 1 在线支付 2全部
        if(payType.length == 2){
          this.$store.commit('setPayType',2);
        }else if(payType[0] == 'online_pay'){
          this.$store.commit('setPayType',1);
        }else if(payType[0] == 'cdkcode_pay'){
          this.$store.commit('setPayType',0);
        }else{
          this.$store.commit('setPayType',0);
        }
        let sso_oauth_notice_video = res.list.filter((item)=>{
          return item.key == 'sso_oauth_notice_video';
        })[0].value;
        this.$store.commit('setSsoOauthNoticeVideo',sso_oauth_notice_video);

      })
    }
  }
}
</script>
<style>
body,html{
  height: 100%;
  min-width: 1380px;
}

#app{
  height: 100%;
}
</style>
