var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"contW"},[_c('div',{staticClass:"banner-nr"},[_c('h1',[_vm._v("火山引擎技能认证")]),_c('p',[_vm._v("我们将先进的数字技术和多年的行业实践经验，整合成一套可学习、可实践、可考核的技能认证标准和体系。通过这个体系，可以提升职业技能水平，开拓更广阔、更多元化的就业空间，为职业生涯注入新的活力和动力。")]),_c('div',{staticClass:"banner-nr-btn",on:{"click":_vm.goAttestation}},[_vm._v("去认证")])])])]),_vm._m(0),_c('div',{staticClass:"attestation"},[_c('div',{staticClass:"contW"},[_c('div',{staticClass:"attestation-top"},[_vm._m(1),_c('div',{staticClass:"attestation-top-btn",on:{"click":_vm.goAttestation}},[_vm._v("更多认证")])]),_c('div',{staticClass:"attestation-cont"},[_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"attestation-cont-item",on:{"click":function($event){return _vm.goInfo(item)}}},[_c('img',{attrs:{"src":item.img,"width":"290","height":"200"}}),_c('div',{staticClass:"attestation-cont-item-tit"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"attestation-cont-item-desc"},[_vm._v(_vm._s(item.desc))]),_c('div',{staticClass:"attestation-cont-item-num"},[_vm._v("共 "+_vm._s(item.map && item.map.node_last_count)+" 节课")])])}),_vm._m(2)],2)])]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"start-attestation"},[_c('div',{staticClass:"contW start-attestation-cont"},[_vm._m(5),_c('div',{staticClass:"start-attestation-btn",on:{"click":_vm.goAttestation}},[_vm._v("去认证")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantage contW"},[_c('div',{staticClass:"advantage-item"},[_c('p',{staticClass:"advantage-item-tit"},[_vm._v("先进的产品技术")]),_c('p',{staticClass:"advantage-item-desc"},[_vm._v("拥有先进的智能技术和成熟的增长理念深耕多年及技术沉淀；")])]),_c('div',{staticClass:"advantage-item"},[_c('p',{staticClass:"advantage-item-tit"},[_vm._v("学练结合模式")]),_c('p',{staticClass:"advantage-item-desc"},[_vm._v("学习与实际训练相结合的模式，提升职业技能水平，开拓多元化就业空间；")])]),_c('div',{staticClass:"advantage-item"},[_c('p',{staticClass:"advantage-item-tit"},[_vm._v("专业的培训赋能")]),_c('p',{staticClass:"advantage-item-desc"},[_vm._v("线上线下有机结合，提供各项技术培训、技术认证、方法论培养等；")])]),_c('div',{staticClass:"advantage-item"},[_c('p',{staticClass:"advantage-item-tit"},[_vm._v("实用技能认证")]),_c('p',{staticClass:"advantage-item-desc"},[_vm._v("将实际应用场景的技术整合可学习、可实践、可考核的技能认证标准和体系；")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-tit"},[_c('img',{attrs:{"src":require("../../assets/images/index/shu.png"),"width":"8","height":"24"}}),_c('p',[_vm._v("技能认证考试")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attestation-cont-item"},[_c('img',{attrs:{"src":require("../../assets/images/index/coming3.png"),"width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full contW"},[_c('div',{staticClass:"home-tit mb30"},[_c('img',{attrs:{"src":require("../../assets/images/index/shu.png"),"width":"8","height":"24"}}),_c('p',[_vm._v("认证全景图")])]),_c('img',{attrs:{"src":require("../../assets/images/index/quanjt.png"),"width":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow-path"},[_c('div',{staticClass:"contW"},[_c('div',{staticClass:"home-tit mb30"},[_c('img',{attrs:{"src":require("../../assets/images/index/shu.png"),"width":"8","height":"24"}}),_c('p',[_vm._v("认证流程")])]),_c('div',{staticClass:"flow-path-cont"},[_c('div',{staticClass:"flow-path-cont-item"},[_c('img',{attrs:{"src":require("../../assets/images/index/xy.png"),"width":"52","height":"52"}}),_c('p',[_c('span',[_vm._v("01")]),_c('span',[_vm._v("报名购买")])])]),_c('img',{staticClass:"flow-path-cont-arrow",attrs:{"src":require("../../assets/images/common/you.png"),"width":"32","height":"22"}}),_c('div',{staticClass:"flow-path-cont-item"},[_c('img',{attrs:{"src":require("../../assets/images/index/rw.png"),"width":"52","height":"52"}}),_c('p',[_c('span',[_vm._v("02")]),_c('span',[_vm._v("在线学习")])])]),_c('img',{staticClass:"flow-path-cont-arrow",attrs:{"src":require("../../assets/images/common/you.png"),"width":"32","height":"22"}}),_c('div',{staticClass:"flow-path-cont-item"},[_c('img',{attrs:{"src":require("../../assets/images/index/zs.png"),"width":"52","height":"52"}}),_c('p',[_c('span',[_vm._v("03")]),_c('span',[_vm._v("认证考试")])])]),_c('img',{staticClass:"flow-path-cont-arrow",attrs:{"src":require("../../assets/images/common/you.png"),"width":"32","height":"22"}}),_c('div',{staticClass:"flow-path-cont-item"},[_c('img',{attrs:{"src":require("../../assets/images/index/js.png"),"width":"52","height":"52"}}),_c('p',[_c('span',[_vm._v("04")]),_c('span',[_vm._v("获得证书")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"start-attestation-cont-l"},[_c('p',[_vm._v("开启认证之旅")]),_c('p',[_vm._v("发挥前沿技术优势，助力学习者更好的获得成长路径与提升空间")])])
}]

export { render, staticRenderFns }