<template>
<div class="home">
  <div class="banner">
    <div class="contW">
      <div class="banner-nr">
        <h1>火山引擎技能认证</h1>
        <p>我们将先进的数字技术和多年的行业实践经验，整合成一套可学习、可实践、可考核的技能认证标准和体系。通过这个体系，可以提升职业技能水平，开拓更广阔、更多元化的就业空间，为职业生涯注入新的活力和动力。</p>
        <div class="banner-nr-btn" @click="goAttestation">去认证</div>
      </div>
    </div>
  </div>
  <div class="advantage contW">
    <div class="advantage-item">
      <p class="advantage-item-tit">先进的产品技术</p>
      <p class="advantage-item-desc">拥有先进的智能技术和成熟的增长理念深耕多年及技术沉淀；</p>
    </div>
    <div class="advantage-item">
      <p class="advantage-item-tit">学练结合模式</p>
      <p class="advantage-item-desc">学习与实际训练相结合的模式，提升职业技能水平，开拓多元化就业空间；</p>
    </div>
    <div class="advantage-item">
      <p class="advantage-item-tit">专业的培训赋能</p>
      <p class="advantage-item-desc">线上线下有机结合，提供各项技术培训、技术认证、方法论培养等；</p>
    </div>
    <div class="advantage-item">
      <p class="advantage-item-tit">实用技能认证</p>
      <p class="advantage-item-desc">将实际应用场景的技术整合可学习、可实践、可考核的技能认证标准和体系；</p>
    </div>
  </div>
  <div class="attestation">
    <div class="contW">
      <div class="attestation-top">
        <div class="home-tit">
          <img src="../../assets/images/index/shu.png" width="8" height="24" />
          <p>技能认证考试</p>
        </div>
        <div class="attestation-top-btn" @click="goAttestation">更多认证</div>
      </div>
      <div class="attestation-cont">
        <div class="attestation-cont-item" v-for="item in list" :key="item.id" @click="goInfo(item)">
          <img :src="item.img" width="290" height="200" />
          <div class="attestation-cont-item-tit">{{ item.name }}</div>
          <div class="attestation-cont-item-desc">{{ item.desc }}</div>
          <div class="attestation-cont-item-num">共 {{ item.map && item.map.node_last_count }} 节课</div>
        </div>
<!--        <div class="attestation-cont-item" >-->
<!--          <img src="../../assets/images/index/coming1.png" width="100%" height="100%" />-->
<!--        </div>-->
<!--        <div class="attestation-cont-item">-->
<!--          <img src="../../assets/images/index/coming2.png" width="100%" height="100%" />-->
<!--        </div>-->
        <div class="attestation-cont-item">
          <img src="../../assets/images/index/coming3.png" width="100%" height="100%" />
        </div>
      </div>
    </div>
  </div>
  <div class="full contW">
    <div class="home-tit mb30">
      <img src="../../assets/images/index/shu.png" width="8" height="24" />
      <p>认证全景图</p>
    </div>
    <img src="../../assets/images/index/quanjt.png" width="100%" />
  </div>
  <div class="flow-path">
    <div class="contW">
      <div class="home-tit mb30">
        <img src="../../assets/images/index/shu.png" width="8" height="24" />
        <p>认证流程</p>
      </div>
      <div class="flow-path-cont">
        <div class="flow-path-cont-item">
          <img src="../../assets/images/index/xy.png" width="52" height="52" />
          <p>
            <span>01</span>
            <span>报名购买</span>
          </p>
        </div>
        <img class="flow-path-cont-arrow" src="../../assets/images/common/you.png" width="32" height="22" />
        <div class="flow-path-cont-item">
          <img src="../../assets/images/index/rw.png" width="52" height="52" />
          <p>
            <span>02</span>
            <span>在线学习</span>
          </p>
        </div>
        <img class="flow-path-cont-arrow" src="../../assets/images/common/you.png" width="32" height="22" />
        <div class="flow-path-cont-item">
          <img src="../../assets/images/index/zs.png" width="52" height="52" />
          <p>
            <span>03</span>
            <span>认证考试</span>
          </p>
        </div>
        <img class="flow-path-cont-arrow" src="../../assets/images/common/you.png" width="32" height="22" />
        <div class="flow-path-cont-item">
          <img src="../../assets/images/index/js.png" width="52" height="52" />
          <p>
            <span>04</span>
            <span>获得证书</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="start-attestation">
    <div class="contW start-attestation-cont">
      <div class="start-attestation-cont-l">
        <p>开启认证之旅</p>
        <p>发挥前沿技术优势，助力学习者更好的获得成长路径与提升空间</p>
      </div>
      <div class="start-attestation-btn" @click="goAttestation">去认证</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "home",
  data(){
    return {
      list:[],
    }
  },
  created(){
    this.getList();
  },
  methods:{
    getList(){
      let params = {
        page_size:0
      };
      this.api.attestation.certList(params).then((res)=>{
        this.list = res.list;
      })
    },
    goAttestation(){
      this.$router.push({
        path:'/attestation'
      })
    },
    goInfo(data){
      this.$router.push({
        path:'/attestation/detail',
        query:{
          id:data.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.home{
  font-size: 14px;
  .banner{
    height: 540px;
    background: url("../../assets/images/index/banner.png") no-repeat;
    background-size: cover;
    .banner-nr{
      padding-top: 150px;
      width: 600px;
      >h1{
        margin-bottom: 32px;
        font-size: 40px;
        color: #1664FF;
      }
      >p{
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
      }
      .banner-nr-btn{
        margin-top: 50px;
        @include btn(140px,46px,23px,16px);
        font-weight: bold;
      }
      .banner-nr-btn:hover{
        @include btnHover($theme-light-blue);
      }
    }
  }
  .advantage{
    padding: 40px 0 34px 0;
    @include flex(row,space-between,flex-start);

    .advantage-item{
      flex: 1;
      border-right: 1px solid $theme-border-gray;;
      color: #666666;
      line-height: 22px;
      .advantage-item-tit{
        width: 242px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      .advantage-item-desc{
        width: 242px;
      }
    }
    .advantage-item:last-child{
      border-right: none;
    }
    .advantage-item:not(:first-child){
      margin-left: 70px;
    }
  }
  .attestation{
    padding: 40px 0 30px 0;
    background: url('../../assets/images/index/attestation_bj.png') no-repeat;
    background-size: cover;
    .attestation-top{
      margin-bottom: 35px;
      @include flex(row,space-between,center);


      .attestation-top-btn{
        @include btn(100px,36px,18px,14px,#333333);
      }
    }
    .attestation-cont{
      @include flex(row,flex-start,flex-start);
      flex-wrap: wrap;

      .attestation-cont-item{
        margin-right: 20px;
        margin-bottom: 30px;
        padding: 28px 20px;
        width: 290px;
        height: 354px;
        background: #FFFFFF;
        border-radius: 10px;
        cursor: pointer;
        .attestation-cont-item-tit{
          margin: 28px 0 12px 0;
          font-size: 20px;
          font-weight: bold;
        }
        .attestation-cont-item-desc{
          height: 40px;
          color: #666666;
          line-height: 22px;
          overflow: hidden;				//溢出内容隐藏
          text-overflow: ellipsis;		//文本溢出部分用省略号表示
          display: -webkit-box;			//特别显示模式
          -webkit-line-clamp: 2;			//行数
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .attestation-cont-item-num{
          margin-top: 20px;
          font-weight: bold;
        }
      }
      .attestation-cont-item:hover{
        .attestation-cont-item-tit{
          color: $theme-blue;
        }
      }
      .attestation-cont-item:nth-child(4n+4){
        margin-right: 0;
      }

    }
  }
  .full{
    padding: 42px 0 50px 0;
  }
  .flow-path{
    padding: 40px 0 50px 0;
    background: #FAFAFA;
    .flow-path-cont{
      margin-top: 50px;
      @include flex(row,space-around,center);
      .flow-path-cont-item{
        @include flex(row,flex-start,center);
        font-size: 16px;
        >p{
          margin-left: 16px;
          >span:nth-child(1){
            margin-right: 8px;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .start-attestation{
    padding: 32px 0;
    background: #E8F0FF;
    .start-attestation-cont{
      @include flex(row,space-between,center);
      font-size: 16px;
      .start-attestation-cont-l{
        color: $theme-blue;
        >p:first-child{
          margin-bottom: 10px;
          font-weight: bold;
        }
      }
      .start-attestation-btn{
        @include btn(140px,46px,23px,16px,#FFFFFF, #1664FF);
        font-weight: bold;
      }

    }
  }
  .home-tit{
    @include flex(row,flex-start,center);
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    >img{
      margin-right: 8px;
    }
  }
}
</style>
