import axios from "axios";
import {Message} from 'element-ui'

import router from "../router/index.js";
// import qs from "qs"

const TOKEN_INVALID = '登录状态已过期，请重新登录！'

const ENTWORK_ERROR = '网络请求异常，请稍后重试！'

//创建axios实例对象，添加全局配置

const service = axios.create({

    baseURL: process.env.VUE_APP_BASE_API,

    timeout: 8000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    }
})


//请求拦截

service.interceptors.request.use(

    (config) => {
        let token = localStorage.getItem('token');
        if (token) {
            config.headers["access-token"] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config;
    },
    (error) => {
        // Do something with request error
        Promise.reject(error);
    }

)

service.interceptors.response.use(
    (res) => {

        // console.log(res)

        if (!res.data.error) {

            return res.data;

        } else if (res.data.error == 'auth_fail') {  //token 过期
            Message.closeAll()
            Message({
                message:TOKEN_INVALID,
                type:'error',
            })

            setTimeout(() => {

                router.push({path:'/login'})

            }, 1500)

            return Promise.reject(TOKEN_INVALID)

        } else {
            Message.error(res.data.message || ENTWORK_ERROR)

            return Promise.reject(res.data.message || ENTWORK_ERROR)
        }
    },
    (err) => {
        Message.error(err.response.data.message || ENTWORK_ERROR)
        return err;
    })


export function request(options) {
    return new Promise((resolve, reject) => {
        service(options)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
