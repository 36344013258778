import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    breadList:[],  //面包屑列表
    payType:0, //支付方式 0 激活码支付 1 在线支付 2全部
    ssoOauthNoticeVideo:'', //单点登录使用视频
  },
  mutations: {
    formatBreadCrumbList(state,data){
      let breadList = localStorage.getItem('breadList');
      state.breadList = breadList ? JSON.parse(breadList) : [];
      if(data.name == 'Home' ){
        state.breadList = [{
          name:data.meta.title,
          url:data.path,
          query:data.query,
          params:data.params,
          dataName:data.name
        }]
      }else{
        if(data.path.indexOf('user') != -1 || data.name == 'attestation'){
          state.breadList = [{
            name:'首页',
            url:'/home',
            dataName:'Home'
          }]
        }
        let index = state.breadList.findIndex((item)=>{
          return item.dataName == data.name
        });
        if(index == -1){
          state.breadList.push({
            name:data.meta.title,
            url:data.path,
            query:data.query,
            params:data.params,
            dataName:data.name
          })
        }else{
          state.breadList.splice(index+1);
        }

      }
      localStorage.setItem('breadList',JSON.stringify(state.breadList));
    },
    setPayType(state,data){
      state.payType = data;
    },
    setSsoOauthNoticeVideo(state,data){
      state.ssoOauthNoticeVideo = data;
    },
  },
  actions: {
  },
  modules: {
  }
})
