import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/css/mix.scss";
import "@/assets/css/global.scss";
import "@/assets/css/common.css";

Vue.config.productionTip = false

import {
  Input,
  Checkbox,
  Collapse,
  CollapseItem,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  TableColumn,
  Message,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  MessageBox,
  Pagination,
  Loading,
} from 'element-ui';

Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;

import api from './api/api';
Vue.prototype.api = api;

// 引入
import myBreadcrumb from '@/components/breadcrumb.vue';
// 注册为全局组件
Vue.component('MyBreadcrumb', myBreadcrumb)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
