import {request} from '@/api/request.js'

//用户详情
const userDetail = (data) => {
    return request({
        url: '/user/detail',
        method: 'post',
        data
    })
}

//实名-是否实名
const isVerifyRealname = (data) => {
    return request({
        url: '/user/is-verify-realname',
        method: 'post',
        data
    })
}

//实名-提交实名
const verifyRealname = (data) => {
    return request({
        url: '/user/verify-realname',
        method: 'post',
        data
    })
}

//认证购买列表
const userCategoryList = (data) => {
    return request({
        url: '/dataset-category/user-list',
        method: 'post',
        data
    })
}

//订单列表(用户)
const orderList = (data) => {
    return request({
        url: '/order/user-list',
        method: 'post',
        data
    })
}

//证书列表
const userSelfList = (data) => {
    return request({
        url: '/dataset-category/user-self-list',
        method: 'post',
        data
    })
}

//下载
const download = (data) => {
    return request({
        url: '/uploadfile/download-base64',
        method: 'POST',
        data
    })
}

//退出登录
const logout = (data) => {
    return request({
        url: '/user/logout',
        method: 'POST',
        data
    })
}

//用户激活
const cdkeyActive = (data) => {
    return request({
        url: '/dataset-cdkey/active',
        method: 'POST',
        data
    })
}

//根据激活用户获取认证列表
const categoryByActivedUser = (data) => {
    return request({
        url: '/dataset-cdkey/category-by-actived-user',
        method: 'POST',
        data
    })
}

// 获取系统配置
const settingList = (data) => {
    return request({
        url: '/setting/list',
        method: 'post',
        data
    })
}





export default {
    userDetail,
    isVerifyRealname,
    verifyRealname,
    userCategoryList,
    orderList,
    userSelfList,
    download,
    logout,
    categoryByActivedUser,
    cdkeyActive,
    settingList
}
