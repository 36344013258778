<template>
<div class="footer">
  <div class="footer-cont contW">
    <div class="footer-cont-t">
      <img src="../../../assets/images/index/blogo.png"  height="29" />
      <div class="footer-cont-t-r">
        <div class="footer-cont-t-r-item">
          <p class="item-tit">技术合作伙伴</p>
          <div class="item-nr">
<!--            <p><a href="https://www.volcengine.com/" target="_blank">火山引擎</a></p>-->
<!--            <p><a href="https://saas.edu360.cn/" target="_blank">实战云</a></p>-->
            <img src="../../../assets/images/index/logo_szy.png"  height="22" style="margin-top:6px" />
          </div>
        </div>
        <div class="footer-cont-t-r-item">
          <p class="item-tit">服务指南</p>
          <div class="item-nr">
            <p><a href="https://shizhanyun.feishu.cn/wiki/Cx7GwNpjKicBPakoftHcpKfKnkg?from=from_copylink" target="_blank">法律声明及个人信息保护政策</a></p>
            <p><a href="https://shizhanyun.feishu.cn/wiki/J6S0wZGK3i8ysakRtOacjkVfnOd?from=from_copylink" target="_blank">常见问题</a></p>
          </div>
        </div>
        <div class="footer-cont-t-r-item">
          <p class="item-tit center">联系我们</p>
          <img class="item-img" src="../../../assets/images/index/ewm.png" width="88" height="88" />
        </div>
      </div>
    </div>
    <div class="footer-cont-b">
      版权所有 © 实战云 未经许可 严禁复制  <span>|</span>  森纵艾数（北京）科技有限公司  <span>|</span>  京ICP备2020036417号 <span>|</span>  地址:北京市朝阳区朝外大街甲10号中认大厦
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "mainFooter"
}
</script>

<style scoped lang="scss">
.footer{
  padding: 45px 0 35px 0;
  background: #262834;
  .footer-cont{
    font-size: 14px;
    font-weight: 300;
    color: #FFFFFF;
    .footer-cont-t{
      @include flex(row,space-between,flex-start);
      .footer-cont-t-r{
        @include flex(row,flex-end,flex-start);

        .footer-cont-t-r-item{
          margin-left: 100px;
          text-align: left;
          .item-tit{
            font-weight: bold;
            color: #FFFFFF;
          }
          .item-nr{
            margin-top: 32px;
            >p{
              font-weight: 300;
              line-height: 30px;
            }
          }
          .item-img{
            margin-top: 14px;
          }
          .center{
            text-align: center;
          }
        }
      }
    }
    .footer-cont-b{
      margin-top: 62px;
      text-align: center;
      >span{
        padding: 0 10px;
      }
    }
  }
}
</style>
