import {request} from '@/api/request.js'

//认证列表
const certList = (data) => {
    return request({
        url: '/index/category-list',
        method: 'post',
        data
    })
}

//认证详情(未登录)
const certDetail = (data) => {
    return request({
        url: '/index/category-detail',
        method: 'post',
        data
    })
}

//认证详情(登录后)
const categoryDetail = (data) => {
    return request({
        url: '/dataset-category/detail',
        method: 'post',
        data
    })
}

//订单
const orderPay = (data) => {
    return request({
        url: '/order/pay',
        method: 'post',
        data
    })
}
//订单
const orderCreate = (data) => {
    return request({
        url: '/order/create',
        method: 'post',
        data
    })
}

//订单-支付状态
const getPayStatus = (data) => {
    return request({
        url: '/order/get-pay-status',
        method: 'post',
        data
    })
}

//资料详情
const sourceDetail = (data) => {
    return request({
        url: '/dataset-source/detail',
        method: 'post',
        data
    })
}

//资料详情
const trainExecute = (data) => {
    return request({
        url: '/dataset-train/execute',
        method: 'post',
        data
    })
}

//更新学习进度
const sourceUpdateProgress = (data) => {
    return request({
        url: '/dataset-source/update-progress',
        method: 'post',
        data
    })
}



export default {
    certList,
    certDetail,
    orderPay,
    orderCreate,
    sourceDetail,
    trainExecute,
    getPayStatus,
    categoryDetail,
    sourceUpdateProgress,
}
